// import React, { useState, useEffect } from 'react';
import './App.css';
// import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import Home from './components/home/Home';
import Items from './components/Items';
import About from './components/About';
import Contact from './components/Contact';


function App() {

  //TODO https://www.npmjs.com/package/react-use-cart
  //TODO react-parallax
    return (
    <Router>
        <div>
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <ul className="navbar-nav mr-auto">
              <li>
                <Link to={'/home'} className="nav-link">
                  <img alt="Logo" src="/logo/logo_transparent_background.png" className="navbar-logo"/>
                </Link>
              </li>
              <li><Link to={'/home'} className="nav-link"> Lumey </Link></li>
              {/*<li><Link to={'/contact'} className="nav-link">Contact</Link></li>*/}
              {/*<li><Link to={'/about'} className="nav-link">About</Link></li>*/}
              {/*<li><Link to={'/items'} className="nav-link">Items</Link></li>*/}
            </ul>
          </nav>
          <Switch>
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
              <Route path='/home' component={Home} />
              <Route path='/contact' component={Contact} />
              <Route path='/about' component={About} />
              <Route path='/items' component={Items} />
          </Switch>
        </div>
    </Router>
  );
}

export default App;
