import React, { Component } from 'react';
// import { Storage } from 'aws-amplify';
import './home.css';

class Home extends Component {

  //TODO npm install react-parallax

  //
  // state = {
  //   comingSoonUrl : "",
  // }
  //
  // constructor(props) {
  //   super(props);
  //   // this.fetchImage();
  // }

  // fetchImage() {
  //   Storage.get('coming_soon.png', {
  //     level: 'public',
  //     bucket: 'lumeybucket82224-staging',
  //     region: 'eu-west-2',
  //   }).then(result => {
  //     console.log(result);
  //     this.setState({
  //       comingSoonUrl: result,
  //     });
  //     return result;
  //   }).catch(err =>
  //     console.log(err)
  //   );
  // }

  // <img src={this.state.comingSoonUrl} alt="Coming Soon"/>

  render () {
    return (
        <div className="container-fluid centered">
          <img src="/img/coming_soon.png" alt="Coming Soon" className="coming-soon"/>
        </div>
    );
  }
}

export default Home;
