/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:ab9b0c05-11da-4b2e-8de8-9cdf0a8edc37",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_ksNI5iJ6W",
    "aws_user_pools_web_client_id": "682clp3d7dn1sjrbm69g4a8qji",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://svf3fqbgsnbfhfpetxxpytu5aa.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qaon3ibisbemfnirh3e745iexq",
    "aws_user_files_s3_bucket": "lumeybucket82224-staging",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
