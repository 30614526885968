
import { useState, useEffect } from 'react';
import { listNotes } from '../graphql/queries';
import { createNote as createNoteMutation, deleteNote as deleteNoteMutation } from '../graphql/mutations';
import { API, Storage } from 'aws-amplify';
import './items.css';
import { withAuthenticator, AmplifySignOut} from '@aws-amplify/ui-react';
import React from 'react';

const Items = () => {

  const initialFormState = { name: '', description: '' }

  const [notes, setNotes] = useState([]);
  const [formData, setFormData] = useState(initialFormState);

  useEffect(() => {
    fetchNotes();
  }, []);

//TODO react-image-magnify

    async function fetchNotes() {
      const apiData = await API.graphql({ query: listNotes });
      const notesFromAPI = apiData.data.listNotes.items;
      await Promise.all(notesFromAPI.map(async note => {
        if (note.image) {
          const image = await Storage.get(note.image);
          note.image = image;
        }
        return note;
      }))
      setNotes(apiData.data.listNotes.items);
    }

    async function createNote() {
      if (!formData.name || !formData.description) return;
      await API.graphql({ query: createNoteMutation, variables: { input: formData } });
      if (formData.image) {
        const image = await Storage.get(formData.image);
        formData.image = image;
      }
      setNotes([ ...notes, formData ]);
      setFormData(initialFormState);
    }


    async function deleteNote({ id }) {
      const newNotesArray = notes.filter(note => note.id !== id);
      setNotes(newNotesArray);
      await API.graphql({ query: deleteNoteMutation, variables: { input: { id } }});
    }

    async function onChange(e) {
      if (!e.target.files[0]) return
      const file = e.target.files[0];
      setFormData({ ...formData, image: file.name });
      await Storage.put(file.name, file);
      fetchNotes();
    }


    return (
      <div className="App">
        <h1>Testing entities with notes</h1>
        <input
          onChange={e => setFormData({ ...formData, 'name': e.target.value})}
          placeholder="Note name"
          value={formData.name}
        />
        <input
          onChange={e => setFormData({ ...formData, 'description': e.target.value})}
          placeholder="Note description"
          value={formData.description}
        />
        <input
          type="file"
          onChange={onChange}
        />
        <AmplifySignOut />
        <button onClick={createNote}>Create Note</button>
        <div style={{marginBottom: 30}}>
        {
          notes.map(note => (


            <div class="row" key={note.id || note.name} >
              <div class="col-4">
                <div class="list-group" id="list-tab" role="tablist">
                  <a class="list-group-item list-group-item-action active" id="list-home-list" data-toggle="list" href="#list-home" role="tab" aria-controls="home">{note.name}</a>

                </div>
              </div>
              <div class="col-8">
                <div class="tab-content" id="nav-tabContent">
                  <div class="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">{
                    note.image && <img alt="NotePic" src={note.image} style={{width: 400}} />
                  }</div>
                  <button onClick={() => deleteNote(note)}>Delete note</button>
                </div>
              </div>
            </div>


            // <div key={note.id || note.name} class="table-row">
            //   <h2>{note.name}</h2>
            //   <p>{note.description}</p>
            //   <button onClick={() => deleteNote(note)}>Delete note</button>
              // {
              //   note.image && <img src={note.image} style={{width: 400}} />
              // }
            // </div>
          ))
        }
        </div>
      </div>
    );

}

export default withAuthenticator(Items);
